//React
import React from 'react'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'

//Assets
import LogoReverse from '../../images/svg/storylava-logo-05.svg'

const Footer = (data) => (
  <StaticQuery
    query={graphql`
      query {
        badgeApple: file(relativePath: { eq: "apple-appstore-badge.png" }) {
          childImageSharp {
            fixed(width: 183, height: 61) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        badgeAndroid: file(relativePath: { eq: "google-play-badge.png" }) {
          childImageSharp {
            fixed(width: 183, height: 54) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    `}
    render={(data) => (
      <footer class="footer">
        <div class="container text-center text-md-left">
          <div class="row row-brand pl-0 pl-md-0">
            <div class="col-md-2 footer-brand-container d-flex align-items-center justify-content-center justify-content-md-start">
              <div class="footer-brand">
                <LogoReverse />
              </div>
            </div>
          </div>
          <div class="row row-menu d-flex flex-row align-items-start justify-content-start">
            <div class="col-md-3 col-menu pb-1 pb-md-0">
              <div class="menu-subhead">Platform</div>
              <ul class="menu menu-platform">
                <li>
                  <Link to="/about/capabilities/">Capabilities</Link>
                </li>
                <li>
                  <Link to="/about/features/">Features</Link>
                </li>
                <li>
                  <Link to="/about/pricing/">Pricing</Link>
                </li>
                {/* <li>
                  <Link to="/about/resources/">Resources</Link>
                </li> */}
                {/* <li>
                  <Link to="/about/tour/brands/">Tour</Link>
                </li> */}
              </ul>
            </div>
            <hr class="d-block d-md-none" />
            <div class="col-md-3 col-menu pb-1 pb-md-0">
              <div class="menu-subhead">Industries</div>
              <ul class="menu menu-platform">
                <li>
                  <Link to="/industry/">See all</Link>
                </li>
                <li>
                  <Link to="/industry/gyms/">Gyms</Link>
                </li>
                <li>
                  <Link to="/industry/real-estate/">Real Estate</Link>
                </li>
                <li>
                  <Link to="/industry/direct-marketing/">Direct Marketing</Link>
                </li>
              </ul>
            </div>
            <hr class="d-block d-md-none" />
            <div class="col-md-3 col-menu pb-1 pb-md-0">
              <div class="menu-subhead">Get started</div>
              <ul class="menu menu-help">
                <li>
                  <Link to="/request-invite/">Request invite</Link>
                </li>
                <li>
                  <a
                    href={process.env.CONSOLE_DOMAIN}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sign in
                  </a>
                </li>
                <li>
                  <a
                    href={process.env.SUPPORT_DOMAIN}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Support
                  </a>
                </li>
                <li>
                  <Link to="/signal/">Signal newsletter</Link>
                </li>
                <li>
                  <a
                    href="https://piconft.net/about/nft-creator/"
                    target="_blank"
                  >
                    NFT Creator
                  </a>
                </li>
                <li>
                  <a
                    href="https://piconft.net/article/how-brand-marketers-use-nfts"
                    target="_blank"
                  >
                    NFT for Brands
                  </a>
                </li>
              </ul>
            </div>
            <hr class="d-block d-md-none" />
            <div class="col-md-3 col-menu pb-1 pb-md-0">
              <div class="menu-subhead">&nbsp;</div>
              <ul class="menu menu-help">
                <li>
                  <Link to="/about/terms/">Terms</Link>
                </li>
                <li>
                  <Link to="/about/privacy/">Privacy</Link>
                </li>
              </ul>
            </div>
            <hr class="d-block d-md-none" />
          </div>
          <div class="row row-copyright text-center text-md-left">
            <div class="col-md-12">&copy; 2021 Storylava</div>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
