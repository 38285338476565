import Amplify from '@aws-amplify/core'
import API from '@aws-amplify/api'

import { amplifyconfig } from '../utils/config'
Amplify.configure(amplifyconfig)

export function saveUnsub(email) {
  let apiName = 'Picopulsemkt'
  let path = 'unsub'

  let options = {
    queryStringParameters: {
      i: email,
    },
  }

  return (dispatch) => {
    dispatch({
      type: 'SUBSCRIBE_LOADING_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.get(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - saveUnsub))')
          console.log(data)

          return resolve(
            dispatch({
              type: 'SUBSCRIBE_SUBMITTED',
              data: data,
            })
          )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}

export function saveSubscribe(email, mode, subscription, access) {
  let apiName = 'Picopulsemkt'
  let path = 'subscribe'

  let options = {
    body: { email, mode, subscription, access },
  }

  console.log('actions- saveSubscribe - email')
  console.log(email)

  return (dispatch) => {
    dispatch({
      type: 'SUBSCRIBE_LOADING_START',
    })
    //begin promise
    new Promise((resolve) => {
      API.post(apiName, path, options)
        .then((response) => {
          const data = response || {}

          console.log('((actions - saveSubscribe))')
          console.log(data)

          dispatch({
            type: 'SUBSCRIBE_SUBMITTED',
            data: data,
          })

          return resolve(
            setTimeout(() => {
              dispatch({
                type: 'FIELD_CHANGE',
                data: { name: 'subSubmitted', val: false },
              })
            }, 12000)
          )
        })
        .catch((error) => {
          console.log(error.response)
        })
    }) //end promise
  }
}
