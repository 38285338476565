//React
import React, { useState } from 'react'

//Gatsby
import { Link } from 'gatsby'

//Assets
import Logo from '../../images/svg/storylava-logo-04.svg'

const Nav = () => {
  const [openContext, setOpenContext] = useState(false)
  return (
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
        <Link
          class="navbar-brand"
          aria-label="Storylava Home"
          activeClassName="navbar-brand"
          to="/"
        >
          <Logo />
        </Link>
        <button
          class="navbar-toggler"
          type="button"
          // data-toggle="collapse"
          // data-target="#navbarText"
          // aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setOpenContext(!openContext)}
        >
          <i class="navbar-toggler-icon"></i>
        </button>
        <div
          class={`navbar-main navbar-collapse ${
            openContext ? '' : 'collapse '
          }`}
          id="navbarText"
        >
          <ul class="navbar-nav mr-auto">
            {/* <li class="nav-item">
              <Link to="/" class="nav-link" activeClassName="nav-link active">
                About
              </Link>
            </li> */}
            <li class="nav-item">
              <Link
                to="/about/capabilities/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Platform
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/about/features/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Features
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link
                to="/about/tour/brands/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Tour
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link
                to="/design/featured/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Designers
              </Link>
            </li> */}
            <li class="nav-item">
              <Link
                to="/industry/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Industries
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/about/pricing/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Pricing
              </Link>
            </li>
            {/* <li class="nav-item">
              <Link
                to="/resources/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Resources
              </Link>
            </li> */}
            <li class="nav-item">
              <a
                href={process.env.SUPPORT_DOMAIN}
                class="nav-link"
                target="_blank"
                activeClassName="nav-link active"
                rel="noopener noreferrer"
              >
                Support
              </a>
            </li>

            {/* <li class="nav-item">
              <Link
                to="/use-cases/retail/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Use cases
              </Link>
            </li>
            <li class="nav-item">
              <Link
                to="/resources/"
                class="nav-link"
                activeClassName="nav-link active"
              >
                Resources
              </Link>
            </li> */}
          </ul>
          <div class="navbar-text d-flex flex-row align-items-center justify-content-center">
            <a
              href={process.env.CONSOLE_DOMAIN}
              class="console-link mr-1"
              activeClassName="nav-link active"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sign in
            </a>
            <Link
              to="/request-invite/"
              class="nav-link"
              activeClassName="nav-link active"
            >
              <div class="btn btn-primary btn-cta">Request invite</div>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Nav
