//React
import React from 'react'
import { connect } from 'react-redux'

//Actions
import { updateCampaign } from '../actions/updateCampaign'

//Packages
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import parse from 'url-parse'

//Components
import NavMain from './nav/navMain'
import NavMinimal from './nav/navMinimal'
import NavStreamlined from './nav/navStreamlined'
import NavNone from './nav/navNone'
import Footer from './nav/footer'
import FooterMinimal from './nav/footerMinimal'
import PrefooterCta from './nav/PrefooterCta'
import PostCta from './marketing/postCta'

//Assets
import favicon from '../images/storylava-icon.png'
import './../scss/main.scss'

//Redux
const mapDispatchToProps = (dispatch) => ({
  updateCampaign: (campaignId) => {
    dispatch(updateCampaign(campaignId))
  },
})

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pageContext: '',
    }
  }

  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.node.isRequired,
  }

  componentDidMount() {
    const url = parse(window.location.href, true)

    console.log('window.location')
    console.log(window.location)
    console.log('((((url))))')
    console.log(url)
    if (url.query && url.query.c) {
      this.props.updateCampaign(url.query.c)
    }
    if (url.pathname === '/') {
      this.setState({
        pageContext: 'home',
      })
    } else if (url.pathname) {
      const split = url.pathname.split('/')
      console.log('((((split))))')
      console.log(split)
      if (url.query && url.query.p && url.query.p === 'app') {
        this.setState({ pageContext: 'force-none' })
      } else if (split[1] === 'about' && split[2] === 'terms') {
        this.setState({ pageContext: 'legal' })
      } else if (split[1] === 'about' && split[2] === 'privacy') {
        this.setState({ pageContext: 'legal' })
      } else if (split[1] === 'about' && split[2] === 'challenge') {
        this.setState({ pageContext: split[2] })
      } else if (split[1] === 'about' && split[2] === 'pricing') {
        this.setState({ pageContext: 'pricing' })
      } else if (split[2] === 'onboarding' && split[3] === 'email') {
        this.setState({ pageContext: 'onboarding-email' })
      } else if (split[1] === 'app' && (!split[2] || split[2] === '')) {
        this.setState({ pageContext: 'app-login' })
      } else if (split[1] === 'app' && split[2] === 'password') {
        this.setState({ pageContext: 'app-password' })
      } else {
        this.setState({ pageContext: split[1] })
      }
    } //END IF
  }

  render() {
    const { children } = this.props

    return (
      <div class="min-vh-100 d-flex flex-column">
        {(() => {
          switch (this.state.pageContext) {
            case 'force-none':
              return <NavNone />
            case 'landing':
            case 'legal':
            case 'unsubscribe':
            case 'request-demo':
            case 'request-invite':
            case 'request-access':
              return <NavMinimal />
            case 'tag':
            case 'profile':
            case 'creative':
              return <NavStreamlined />
            case 'home':
              return <NavMain />
            default:
              return <NavMain />
          }
        })()}
        {/* {`CONTEXT:: ${this.state.pageContext}`} */}
        {children}

        {(() => {
          switch (this.state.pageContext) {
            case 'force-none':
            case 'landing':
            case 'tag':
            case 'profile':
            case 'creative':
            case 'legal':
            case 'unsubscribe':
              return <FooterMinimal />

            case 'subscription':
            case 'signal':
            case 'request-invite':
            case 'request-demo':
            case 'request-access':
              return (
                <>
                  <Footer />
                </>
              )
            case 'articles':
            case 'article':
              return (
                <>
                  {/* <PrefooterCta context="notify" /> */}
                  <PostCta
                    context="emailSub"
                    containerClass="border-top-1-gray-6 pt-4 container"
                    subClass="col-lg-10 offset-lg-1"
                  />
                  <PostCta
                    containerClass="container"
                    subClass="col-lg-10 offset-lg-1"
                  />
                  <Footer />
                  {/* <DriftBot /> */}
                </>
              )
            case 'pricing':
            case 'home':
              return (
                <>
                  {/* <PrefooterCta context="notify" /> */}
                  {/* <div class="row flex-row">
                    <div class="col-md-6">
                      <PostCta context="emailSub" containerClass="ml-2" />
                    </div>
                    <div class="col-md-6">
                      <PostCta containerClass="mr-2 d-flex align-items-stretch h-100" />
                    </div>
                  </div> */}
                  <PostCta containerClass="mt-3 mx-2 mx-lg-5" />
                  <PostCta
                    context="emailSub"
                    containerClass="pt-2 mx-2 mb-4 mx-lg-5"
                  />
                  <Footer />
                  {/* <DriftBot /> */}
                </>
              )
            default:
              return (
                <>
                  <PostCta
                    context="emailSub"
                    containerClass="border-top-1-gray-6 pt-4 mx-2 mx-lg-5"
                  />
                  <PostCta containerClass="mx-2 mx-lg-5" />

                  {/* <PrefooterCta context="notify" /> */}
                  <Footer />
                </>
              )
          }
        })()}
      </div>
    )
  }
}

export default connect(null, mapDispatchToProps)(IndexPage)
